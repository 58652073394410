import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cardData: {
    card_number: '',
    card_exp_month: '',
    card_exp_year: '',
    card_cvv: '',
  },
  save_cc: false,
  isOpenNewCardForm: false,
};

const creditDebitCardSlice = createSlice({
  name: 'CREDIT_DEBIT_CARD',
  initialState,
  reducers: {
    RESET_CARD_DATA(state) {
      state.cardData = {
        card_number: '',
        card_exp_month: '',
        card_exp_year: '',
        card_cvv: '',
      };
    },
    SET_CARD_DATA(state, { data }) {
      const [cardDataPropertyName] = Object.keys(data);
      if (cardDataPropertyName === 'validity_period') {
        if (data.validity_period.includes('/')) {
          const [card_exp_month, card_exp_year] =
            data.validity_period.split('/');
          state.cardData.card_exp_month = card_exp_month;
          state.cardData.card_exp_year = card_exp_year;
        } else {
          state.cardData.card_exp_month = '';
          state.cardData.card_exp_year = '';
        }
        return;
      }

      state.cardData = { ...state.cardData, ...data };
    },
    SET_IS_OPEN_NEW_CARD_FORM(state, { data }) {
      state.isOpenNewCardForm = data;
    },
    SET_SAVE_CC(state, { data }) {
      state.save_cc = data;
    },
  },
});

export const { SET_CARD_DATA, SET_SAVE_CC, SET_IS_OPEN_NEW_CARD_FORM } =
  creditDebitCardSlice.actions;
export default creditDebitCardSlice.reducer;
