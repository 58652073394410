import { createAsyncThunk } from '@reduxjs/toolkit';
import { Soco } from '~api/soco';

export const updateReview = createAsyncThunk(
  'createReview/updateReview',
  async ({ payload, id }, { rejectWithValue }) => {
    try {
      const response = await Soco.updateReview({ payload, id });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
);
