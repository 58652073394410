import { createAsyncThunk } from '@reduxjs/toolkit';
import { Soco } from '~api/soco';

export const searchProductRequest = createAsyncThunk(
  'createReview/searchProductRequest',
  async (params, { rejectWithValue }) => {
    try {
      const response = await Soco.searchProductsRequest(params);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
);

export const addReview = createAsyncThunk(
  'createReview/addReview',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Soco.addReview(payload);
      return response.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
);

export const getReviews = createAsyncThunk(
  'createReview/getReviews',
  async (params, { rejectWithValue }) => {
    try {
      const response = await Soco.getReviews(params);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
);
