import { createSlice } from '@reduxjs/toolkit';
import {
  getFlashSale,
  loadMoreFlashSale,
  getTotalFlashSale,
  getBannerFlashSale,
} from '~store/flash-sale/thunks';

export const { reducer } = createSlice({
  name: 'flashSale',
  initialState: {
    data: [],
    active: null,
    regularFlashsaleTheme: [],
    totalFlashSale: {},
    flashSaleBanner: [],
    status: {
      flashSaleBanner: 'idle',
      flashSale: 'idle',
      flashSaleLoadMore: 'idle',
      totalFlashSale: 'idle',
    },
    indexCurrentFlashSale: -1,
  },
  reducers: {
    setFlashSaleData(state, { data }) {
      state.data = data;
    },
    setRegularFlashSaleTheme(state, { data }) {
      state.regularFlashsaleTheme = data;
    },

    setIndexFlashSale(state, { data }) {
      state.indexCurrentFlashSale = data;
    },

    mergeProductsFlashSale(state, { data, key }) {
      state.data[key].products = [...state.data[key].products, ...data];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFlashSale.rejected, (state) => {
        state.status.flashSale = 'failed';
      })
      .addCase(getFlashSale.pending, (state) => {
        state.status.flashSale = 'loading';
      })
      .addCase(getFlashSale.fulfilled, (state, { payload }) => {
        if (payload) {
          if (payload?.isSpecific) {
            state.data[payload.indexData] = payload.data;
          } else {
            state.data = payload.data;
          }
        }
        state.status.flashSale = 'succeeded';
      })
      .addCase(loadMoreFlashSale.rejected, (state) => {
        state.status.flashSaleLoadMore = 'failed';
      })
      .addCase(loadMoreFlashSale.pending, (state) => {
        state.status.flashSaleLoadMore = 'loading';
      })
      .addCase(loadMoreFlashSale.fulfilled, (state, { payload }) => {
        state.status.flashSaleLoadMore = 'succeeded';
      })
      .addCase(getBannerFlashSale.rejected, (state, { error }) => {
        state.status.flashSaleBanner = 'failed';
      })
      .addCase(getBannerFlashSale.pending, (state) => {
        state.status.flashSaleBanner = 'loading';
      })
      .addCase(getBannerFlashSale.fulfilled, (state, { payload }) => {
        state.flashSaleBanner = payload.data;
        state.status.flashSaleBanner = 'succeeded';
      })
      .addCase(getTotalFlashSale.rejected, (state) => {
        state.status.totalFlashSale = 'failed';
      })
      .addCase(getTotalFlashSale.pending, (state) => {
        state.status.totalFlashSale = 'loading';
      })
      .addCase(getTotalFlashSale.fulfilled, (state, { payload }) => {
        state.status.totalFlashSale = 'succeeded';
        state.totalFlashSale[payload.id] = payload.data;
      });
  },
});

export default reducer;
