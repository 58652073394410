import { createAsyncThunk } from '@reduxjs/toolkit';
import { Catalog } from '~api/catalog';

export const getFlashSale = createAsyncThunk(
  'flashSale/getFlashSale',
  async (params) => {
    try {
      const response = await Catalog.getFlashSale(params);
      if (params.filter.product_price_rule) {
        const totalData = response.data.data.length;
        for (let i = 0; i < totalData; i++) {
          const data = response.data.data[i];
          if (data['_id'] === params.filter.product_price_rule) {
            response.data.indexData = i;
            response.data.data = data;
            break;
          }
        }

        response.data.isSpecific = true;
      }
      for (let i = 0; i < response.data.data.length; i++) {
        const data = response.data.data[i];
        const products = data.products;

        if (Array.isArray(products) && products.length > 0) {
          products.map((product, indexProduct) => {
            if (product && 'combinations' in product) {
              products[indexProduct].original_combinations =
                product.combinations;
              const combinationInFlashSale = product.combinations.filter(
                (combination) =>
                  'is_flashsale' in combination && combination.is_flashsale,
              );

              if (
                (combinationInFlashSale.length == product.combinations.length &&
                  product.combinations.length > 1) ||
                !combinationInFlashSale.length
              ) {
                products[indexProduct].selected_variant = false;
              } else {
                products[indexProduct].selected_variant = true;
              }
              if (products[indexProduct].selected_variant) {
                if (!products[indexProduct].default_combination.is_flashsale) {
                  products[
                    indexProduct
                  ].default_combination.is_flashsale = true;
                }
                products[indexProduct].combinations = [];
                products[indexProduct].combinations.push(
                  products[indexProduct].default_combination,
                );
                const combinationKeys = Object.keys(
                  products[indexProduct].default_combination,
                );
                combinationKeys.forEach((key) => {
                  if (key in products[indexProduct]) {
                    products[indexProduct][key] =
                      products[indexProduct].default_combination[key];
                  }
                });
              }
            }
          });
        }
      }
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
);

export const getBannerFlashSale = createAsyncThunk(
  'flashSale/getBannerFlashSale',
  async (params) => {
    try {
      const response = await Catalog.bannersRequest(params);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
);

export const loadMoreFlashSale = createAsyncThunk(
  'flashSale/loadMoreFlashSale',
  async (params) => {
    try {
      const response = await Catalog.getFlashSale(params);

      for (let i = 0; i < response.data.data.length; i++) {
        const data = response.data.data[i];
        const products = data.products;

        if (Array.isArray(products) && products.length > 0) {
          products.map((product, indexProduct) => {
            if (product && 'combinations' in product) {
              products[indexProduct].original_combinations =
                product.combinations;
              const combinationInFlashSale = product.combinations.filter(
                (combination) =>
                  'is_flashsale' in combination && combination.is_flashsale,
              );

              if (
                (combinationInFlashSale.length == product.combinations.length &&
                  product.combinations.length > 1) ||
                !combinationInFlashSale.length
              ) {
                products[indexProduct].selected_variant = false;
              } else {
                products[indexProduct].selected_variant = true;
              }
              if (products[indexProduct].selected_variant) {
                if (!products[indexProduct].default_combination.is_flashsale) {
                  products[
                    indexProduct
                  ].default_combination.is_flashsale = true;
                }
                products[indexProduct].combinations = [];
                products[indexProduct].combinations.push(
                  products[indexProduct].default_combination,
                );
                const combinationKeys = Object.keys(
                  products[indexProduct].default_combination,
                );
                combinationKeys.forEach((key) => {
                  if (key in products[indexProduct]) {
                    products[indexProduct][key] =
                      products[indexProduct].default_combination[key];
                  }
                });
              }
            }
          });
        }
      }
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
);

export const getTotalFlashSale = createAsyncThunk(
  'flashSale/getTotalFlashSale',
  async (id) => {
    try {
      const response = await Catalog.getTotalFlashSale(id);
      response.data.id = id;
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
);
