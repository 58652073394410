import { createSlice } from '@reduxjs/toolkit';
import { updateReview } from '~store/edit-review/thunks';
import { isWeb } from '~global/screens';

export const { reducer } = createSlice({
  name: 'editReview',
  initialState: {
    step: 1,
    section: 'give_rating',
    selectedProduct: null,
    selectedReview: null,
    averageRatingProduct: 0,
    averageRatingText: '',
    userPhotos: [],
    payload: {
      details: '',
      duration_of_used: '',
      is_recommended: '',
      is_repurchase: '',
    },
    status: {
      updateReview: 'idle',
    },
  },
  reducers: {
    setSelectedProduct(state, { data: product }) {
      const data = { ...product }; // prevent object is not extensible
      const combinations = [];
      let is_contains_shade = false;
      if (data.combinations && data.combinations.length) {
        data.combinations.forEach((combination, key) => {
          if (
            combination.attributes &&
            Object.keys(combination.attributes).length &&
            combination.is_active_in_review
          ) {
            let combination_group_name_html = '';
            const combination_group_shade = {};

            for (const attribute in combination.attributes) {
              let attribute_name;
              // eslint-disable-next-line no-prototype-builtins
              if (combination.attributes.hasOwnProperty(attribute)) {
                if (
                  attribute !== 'non_specify' &&
                  (combination.attributes[attribute].my_sociolla_sql_id ||
                    combination.attributes[attribute].my_soco_sql_id)
                ) {
                  // eslint-disable-next-line no-const-assign
                  combination_group_name_html = combination_group_name_html
                    ? combination_group_name_html + ', '
                    : combination_group_name_html;
                  if (
                    combination.attributes[attribute].value !== null &&
                    combination.attributes[attribute].value.search('#') !==
                      -1 &&
                    combination.attributes[attribute].value.length <= 7
                  ) {
                    combination_group_shade.hex =
                      combination.attributes[attribute].value;
                  } else {
                    combination_group_shade.url =
                      combination.attributes[attribute].value;
                  }
                  attribute_name = attribute.replace(
                    /(?:^|\s)\S/g,
                    function (a) {
                      return a.toUpperCase();
                    },
                  );
                  // eslint-disable-next-line no-const-assign
                  combination_group_name_html += `${attribute_name} - ${combination.attributes[attribute].name}`;
                  if (attribute === 'shade') {
                    combination_group_shade.name = attribute.replace(
                      /(?:^|\s)\S/g,
                      function (a) {
                        return a.toUpperCase();
                      },
                    );
                    // eslint-disable-next-line no-const-assign
                    is_contains_shade = true;
                  }
                }
              }
            }

            if (combination_group_name_html) {
              let name_html = combination_group_name_html;
              const explode_combination_to_array = name_html.split(',');

              if (
                explode_combination_to_array.length > 1 &&
                explode_combination_to_array[1].indexOf('shade')
              ) {
                name_html =
                  explode_combination_to_array[1] +
                  ', ' +
                  explode_combination_to_array[0];
              }

              combinations.push({
                shade: combination_group_shade,
                name_html: name_html,
                name: name_html.trim(),
                images: combination.images,
                is_contains_shade,
                _id: combination['_id'],
                is_active_in_review: combination.is_active_in_review,
                is_reviewed: combination.is_reviewed,
                is_verified_purchase: combination.is_verified_purchase,
              });
            }
          }
        });
      }
      // ratingType

      const ratingTypesTemp = (() => {
        const types = [];
        const averageRatingByTypes =
          data.review_stats &&
          data.review_stats.average_rating_by_types &&
          Object.keys(data.review_stats.average_rating_by_types).length
            ? data.review_stats.average_rating_by_types
            : {};
        Object.entries(averageRatingByTypes).map(([name, value], index) => {
          const objectType = { name: '', value: 0, isActive: false };
          objectType.name = name.toLowerCase();
          objectType.name = objectType.name.replace(/is_star_|star_/gi, '');
          objectType.name = objectType.name.replace(/[_]/gi, ' ');
          // update payload
          state.payload[`is_${name}`] = 0;
          types.push(objectType);
        });
        return types;
      })();

      if (isWeb) {
        const ratings = Object.fromEntries(
          Object.entries(state.selectedReview).filter(
            ([name, value]) => /star_/gi.test(name) && [name, value],
          ),
        );
        data.ratingTypes = ratingTypesTemp.map((rating) => {
          const ratingName = `star_${rating.name.replace(/ +/g, '_')}`;
          return { ...rating, value: ratings[ratingName] };
        });
      } else {
        data.ratingTypes = ratingTypesTemp;
      }
      data.list_combinations = combinations;
      data.currentCombination = data.default_combination
        ? data.default_combination
        : data.combinations;
      state.selectedProduct = data;
    },
    setState(state, { name, value }) {
      state[name] = value;
    },
    setStatusApi(state, { name, value }) {
      state.status[name] = value;
    },
    resetState(state) {
      state.step = 1;
      state.section = 'select_product';
      state.selectedProduct = null;
      state.selectedReview = null;
      state.averageRatingProduct = 0;
      state.averageRatingText = '';
      state.userPhotos = [];
      state.payload = {
        details: '',
        duration_of_used: '',
        is_recommended: '',
        is_repurchase: '',
      };
      state.status = {
        updateReview: 'idle',
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateReview.rejected, (state) => {
        state.status.updateReview = 'failed';
      })
      .addCase(updateReview.pending, (state) => {
        state.status.updateReview = 'loading';
      })
      .addCase(updateReview.fulfilled, (state, { payload }) => {
        state.status.updateReview = 'succeeded';
      });
  },
});

export default reducer;
