import { createSlice } from '@reduxjs/toolkit';
import {
  searchProductRequest,
  addReview,
  getReviews,
} from '~store/create-review/thunks';

export const { reducer } = createSlice({
  name: 'createReview',
  initialState: {
    step: 1,
    section: 'select_product',
    searchProductResult: [],
    selectedProduct: null,
    selectedCombination: null,
    selectedIndexCombination: -1,
    selectedCombinationName: '',
    averageRatingProduct: 0,
    averageRatingText: '',
    userPhotos: [],
    productReviewType: '',
    reviewInSpecificProduct: [],
    payload: {
      details: '',
      duration_of_used: '',
      is_recommended: '',
      is_repurchase: '',
    },
    status: {
      searchProductResult: 'idle',
      addReview: 'idle',
      reviewInSpecificProduct: 'idle',
    },
    isEditReview: false,
    inputSearchText: '',
  },
  reducers: {
    setSelectedProduct(state, { data }) {
      const combinations = [];
      let is_contains_shade = false;
      const product = JSON.parse(JSON.stringify(data || {}));
      if (product.combinations && product.combinations.length) {
        product.combinations.forEach((combination, key) => {
          if (
            combination.attributes &&
            Object.keys(combination.attributes).length
          ) {
            let combination_group_name_html = '';
            const combination_group_shade = {};

            for (const attribute in combination.attributes) {
              let attribute_name;
              // eslint-disable-next-line no-prototype-builtins
              if (combination.attributes.hasOwnProperty(attribute)) {
                if (
                  attribute !== 'non_specify' &&
                  (combination.attributes[attribute].my_sociolla_sql_id ||
                    combination.attributes[attribute].my_soco_sql_id)
                ) {
                  combination_group_name_html = combination_group_name_html
                    ? combination_group_name_html + ', '
                    : combination_group_name_html;
                  if (
                    combination.attributes[attribute].value !== null &&
                    combination.attributes[attribute].value.search('#') !==
                      -1 &&
                    combination.attributes[attribute].value.length <= 7
                  ) {
                    combination_group_shade.hex =
                      combination.attributes[attribute].value;
                  } else {
                    combination_group_shade.url =
                      combination.attributes[attribute].value;
                  }
                  // eslint-disable-next-line no-unused-vars
                  attribute_name = attribute.replace(
                    /(?:^|\s)\S/g,
                    function (a) {
                      return a.toUpperCase();
                    },
                  );
                  combination_group_name_html += `${combination.attributes[attribute].name}`;
                  if (attribute === 'shade') {
                    combination_group_shade.name = attribute.replace(
                      /(?:^|\s)\S/g,
                      function (a) {
                        return a.toUpperCase();
                      },
                    );
                    // eslint-disable-next-line no-const-assign
                    is_contains_shade = true;
                  }
                }
              }
            }

            if (combination_group_name_html) {
              let name_html = combination_group_name_html;
              const explode_combination_to_array = name_html.split(',');

              if (
                explode_combination_to_array.length > 1 &&
                explode_combination_to_array[1].indexOf('shade')
              ) {
                name_html =
                  explode_combination_to_array[1] +
                  ', ' +
                  explode_combination_to_array[0];
              }

              combinations.push({
                shade: combination_group_shade,
                name_html: name_html,
                name: name_html.trim(),
                images: combination.images,
                is_contains_shade,
                _id: combination['_id'],
                is_active_in_review: combination.is_active_in_review,
                is_reviewed: combination.is_reviewed,
                is_verified_purchase: combination.is_verified_purchase,
              });
            }
          }
        });
      }
      // ratingType

      const ratingTypesTemp = (() => {
        const types = [];
        const averageRatingByTypes =
          product?.review_stats?.average_rating_by_types &&
          Object.keys(product.review_stats.average_rating_by_types)?.length
            ? product.review_stats.average_rating_by_types
            : product?.default_category?.rating_types;
        // let ratingData = Array.isArray(averageRatingByTypes) ? averageRatingByTypes : Object.entries(averageRatingByTypes ?? []);
        // ratingData.map((name, index) => {
        // 	let objectType = { name: '', value: 0, isActive: false }
        // 	objectType.name = name.toLowerCase()
        // 	objectType.name = objectType.name.replace(/is_star_|star_/ig, '')
        // 	objectType.name = objectType.name.replace(/[_]/gi, ' ')
        // 	// update payload
        // 	state.payload[`is_${name}`] = 0
        // 	types.push(objectType)
        // })
        if (Array.isArray(averageRatingByTypes)) {
          averageRatingByTypes.map((name, index) => {
            const objectType = { name: '', value: 0, isActive: false };
            objectType.name = name.toLowerCase();
            objectType.name = objectType.name.replace(/is_star_|star_/gi, '');
            objectType.name = objectType.name.replace(/[_]/gi, ' ');
            // update payload
            state.payload[`is_${name}`] = 0;
            types.push(objectType);
          });
        } else {
          Object.entries(averageRatingByTypes ?? []).map(
            ([name, value], index) => {
              const objectType = { name: '', value: 0, isActive: false };
              objectType.name = name.toLowerCase();
              objectType.name = objectType.name.replace(/is_star_|star_/gi, '');
              objectType.name = objectType.name.replace(/[_]/gi, ' ');
              // update payload
              state.payload[`is_${name}`] = 0;
              types.push(objectType);
            },
          );
        }

        return types;
      })();

      product.ratingTypes = ratingTypesTemp;
      product.list_combinations = combinations;
      product.currentCombination = product.default_combination
        ? product.default_combination
        : product.combinations;
      state.selectedProduct = product;
      state.selectedCombination = product.list_combinations;
    },
    setState(state, { name, value }) {
      state[name] = value;
    },
    setStatusApi(state, { name, value }) {
      state.status[name] = value;
    },
    resetState(state) {
      state.step = 1;
      state.section = 'select_product';
      state.searchProductResult = [];
      state.selectedProduct = null;
      state.selectedCombination = null;
      state.selectedCombinationName = '';
      state.selectedIndexCombination = -1;
      state.averageRatingProduct = 0;
      state.averageRatingText = '';
      state.userPhotos = [];
      state.reviewInSpecificProduct = [];
      state.payload = {
        details: '',
        duration_of_used: '',
        is_recommended: '',
        is_repurchase: '',
      };
      state.status = {
        searchProductResult: 'idle',
        addReview: 'idle',
        reviewInSpecificProduct: 'idle',
      };
    },
    setInputSearchText(state, { value }) {
      state.inputSearchText = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchProductRequest.rejected, (state) => {
        state.status.searchProductResult = 'failed';
      })
      .addCase(searchProductRequest.pending, (state) => {
        state.status.searchProductResult = 'loading';
      })
      .addCase(searchProductRequest.fulfilled, (state, { payload }) => {
        state.searchProductResult = payload.data;
        state.status.searchProductResult = 'succeeded';
      })
      .addCase(addReview.rejected, (state) => {
        state.status.addReview = 'failed';
      })
      .addCase(addReview.pending, (state) => {
        state.status.addReview = 'loading';
      })
      .addCase(addReview.fulfilled, (state, { payload }) => {
        state.status.addReview = 'succeeded';
      })
      .addCase(getReviews.rejected, (state) => {
        state.status.reviewInSpecificProduct = 'failed';
      })
      .addCase(getReviews.pending, (state) => {
        state.status.reviewInSpecificProduct = 'loading';
      })
      .addCase(getReviews.fulfilled, (state, { payload }) => {
        state.reviewInSpecificProduct = payload.data;
        state.status.reviewInSpecificProduct = 'succeeded';
      });
  },
});

export default reducer;
